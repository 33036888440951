@import "./variables.scss";

@import "./components/Header.scss";
@import "./components/cards/IntroCard.scss";
@import "./components/cards/ServiceCard.scss";
@import "./components/cards/ContactCard.scss";
@import "./components/cards/ClientsCard.scss";
@import "./components/cards/PricingCard.scss";

@import "./layouts/Default.scss";
@import "./pages/Home.scss";
@import "./pages/Login.scss";
@import "./pages/Admin.scss";



@import "./pages/NewHome.scss";



/* @import "./clients/prednaHora.scss";
@import "./clients/panorama.scss";
@import "./clients/jazzCafe.scss";
@import "./clients/obyvackaBar.scss";
@import "./clients/mercyCoffee.scss";
@import "./clients/justLovelyCafe.scss"; */


@import "./clients/global.scss";
/* RENAME SEASONAL.SCSS to HALLOWEEN.SCSS */
/* @import "./clients/seasonal.scss"; */


html {
  scroll-behavior: smooth;
  scrollbar-width: none;
}
@keyframes droppable_animation {
  from {
    height: 0px;
  }
  to {
    height: 28px;
  }
}
.droppable_space {
  animation: droppable_animation 0.3s ease-in;
  background-color: rgba(221, 221, 221, 0.583);
  box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
 /*  margin-bottom: 5px; */
  border-radius: 4px;
  overflow: hidden;
  /* transition: 0.3s; */
  margin-bottom: 10px;
  cursor: default;
  /* width: 100%;
  &:hover {
    width: 95%;
  } */
}
.droppable_space_ontop {
}
.droppable_space_below {
  margin-top: 10px;
}
.table_class_move {
  user-select: none;
}
.drabbable {
  cursor: move !important; /* fallback if grab cursor is unsupported */
  cursor: grab !important;
  cursor: -moz-grab !important;
  cursor: -webkit-grab !important;
  user-select: none !important;
  .icon {
    user-select: none !important;
    background-color: transparent !important;

  }
}
.drabbable:active {
  cursor: grabbing !important;
  cursor: -moz-grabbing !important;
  cursor: -webkit-grabbing !important;
  /* background-color: #966DEC !important; */
  color: white !important;
}
::-moz-selection { /* Code for Firefox */
  color: $white_color;
  background: #966DEC;
}
::-webkit-scrollbar{
  display: none;
}

::selection {
  color: $white_color;
  background: #966DEC;
}
.bold {
  font-weight: 500 !important;
}
.loading_icon {
  animation: loading_animation 0.7s infinite;
  position: absolute;
  left: 15px;
  top: 15px;
}
@keyframes loading_animation {
  100% {
    transform: rotate(360deg);
  }
}
.input_group {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 10px;
}
.checkbox_group {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
/* Keyframes for sliding background */
@keyframes slideFromTopLeft {
  0% {
    background-position: -100vh -100vw;
  }
  100% {
    background-position: 0 0;
  }
}
@keyframes slideToTopLeft {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -100vh -100vw;
  }
}
@keyframes scaleBackground {
  0% {
    background-size: 700vw 700vh;
  }
  100% {
    background-size: 100vw 100vh;
  }
}
@keyframes outScaleBackground {
  0% {
    background-size: 100vw 100vh;
  }
  100% {
    background-size: 400vw 400vh;
  }
}
.scale-background {

  animation: scaleBackground 0.6s ease-out forwards;
}
.hide-scale-background {
  animation: outScaleBackground 0.8s ease-out forwards;
}
/* Class to trigger the animation */
.slide-background {
  animation: slideFromTopLeft 0.5s ease-out forwards;
}
.hide-slide-background {
  animation: slideToTopLeft 1s ease-out forwards;
}
body {

  background-image: url("../images/new_background.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-size: 500vw 500vh;
  @media only screen and (max-width: 768px) {
    background-image: none;
  }
  scroll-behavior: smooth;
  color: #ffffff;

  overflow: hidden auto;

  margin: 0;
  font-family: 'Kanit', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: -light;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.text-left {
  text-align: start;
}
/* .moje_qr_loader_logo_wrap {
  position: absolute;
  padding: 35px;
  border-radius: 100%;
  background-color: #6440DF;
  height: 80px;
  width: 80px;
  z-index: 999;
  top: 50%;
  left: 50%;
  transform: translate(-50%,  -100vh);
  animation: image_animation 1s ease-out;
  img {
    width: 100%;
  }

  @keyframes image_animation {
    0% {
      transform: translate(-50%, -50%)
    }
    100% {
      transform: translate(-50%, -100vh)
    }
  }
}
.moje_qr_loader {
  position: absolute;
  background-color: #6440DF;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  animation: bg_animation 2s ease-in ;
  opacity: 0;

  @keyframes bg_animation {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
} */
.modal_bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba($color: #000000, $alpha: .4);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  .modal_close_icon {
    cursor: pointer;
    background-color: white;
    padding: 5px;
    border-radius: 100%;
    position: absolute;
    right: 20px;
    top: 20px;

  }
}
#root {
  min-height: 100vh;
}

#INCLUDING_IMAGE{
  /* position: absolute; */
  transform: translateY(-5px) translateX(2px);
}

.recommendedModal {
  position: fixed;
  z-index: 998;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  opacity: .4;
  display: flex;
  justify-content: center;
}
.recommendedModal_inner_wrap {
  top: 15px;
  width: 100vw;
  position: absolute;
  z-index: 999;
  display: flex;
  justify-content: center;
  .recommendedModal_modal {
    height: 80px;
    border-radius: 8px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
    .icon_wrap {
      .icon {
        user-select: none !important;
      }
    }
    .text_wrap{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      padding-left: 20px;
      font-size: 25px;
      font-weight: 500;
      .drink_wrap {
        font-size: 20px;
        font-weight: 500;
        display: flex;
        justify-content: space-between;
      }
    }
  }
}
.flagImage {
  /* max-height: 22px; */
  position: relative;
  padding-top: 3px;
  margin-left: 5px;
  /* opacity: 0.49; */
  transform: translateY(3px) rotate(-2deg);

  @media screen and (max-width:600px) {
    transform: translateY(3px) rotate(-2deg);
  }

}

.imageModal {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  background-color: rgba($color: #000000, $alpha: .9);
  width: 100vw;
  height: 100vh;
  display: none;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: 0.5s;
  transition-timing-function: ease-in-out;

  #IMAGE_MODAL_CLOSE_BUTTON_WRAP {
    position: fixed;
    z-index: 999;
    right: 15px;
    top: 10px;
    width: 50px;
    display: flex;
    justify-content: flex-end;
  }
  #IMAGE_MODAL_CLOSE_BUTTON {
    cursor: pointer;
  }
  .imageModal_image {
    text-align: center;
    position: relative;
    margin-top: -80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
      transition: 0.5s;
      transition-timing-function: ease-in-out;
      border-radius: 8px;
      max-height: 80vh;
      max-width: 92vw;
      transform: translateY(100vh);
    }
    h2 {
      margin-top: 15px;
      margin-bottom: 15px;
      font-weight: 600;
      opacity: 0;
      transform: translateY(80px);
      transition: 0.5s;
    }
    #IMAGE_MODAL_EXPANDABLE_CONTENT {
      border-top: 2px solid white;
      padding-top: 5px;
      margin-top: 15px;
      margin-bottom: 15px;
      font-weight: 500;
      opacity: 0;
      transform: translateY(80px);
      max-width: 600px;
      @media screen and (max-width:600px) {
        max-width: 78vw;
      }
      text-align:justify;
      transition: 0.5s;
    }
    #IMAGE_MODAL_LEFT {
      background-color: transparent;
      /* background-color: rgba($color: #fd0000, $alpha: .4); */
      height: 80vh;
      width: 50%;
      top: 65px;
      position: absolute;
    }
    #IMAGE_MODAL_RIGHT {
      background-color: transparent;
      /* background-color: rgba($color: #ffa200, $alpha: .4); */
      height: 80vh;
      width: 50%;
      top: 65px;
      right: 0;
      position: absolute;
    }
  }
}

.halloween {
    z-index: 1 !important;
    width: 100%;
    height: 100%;
    position: absolute;
    overflow-x: hidden;


    .ghost {
      z-index: 999 !important;
      $localGhostWidth: 130px;
      $localGhostBottom: 40px;
      $localGhostJump: 40px;

      width: $localGhostWidth;
      position: absolute;
      animation: ghostAnimation 80s ease-in-out infinite, upAndDown 2s ease-in-out infinite;
      @media screen and (max-width:600px) {
        animation: ghostAnimation 30s ease-in-out infinite, upAndDown 2s ease-in-out infinite;
      }
      bottom: $localGhostBottom;
      right: 0px;
      @keyframes ghostAnimation {
        0% {
          transform: translateX(calc($localGhostWidth + 50px)) scaleX(1);
        }
        49% {
          transform: translateX(-149vw) scaleX(1);
        }
        50% {
          transform: translateX(-150vw) scaleX(-1);
        }
        99% {
          transform: translateX(calc($localGhostWidth + 50px)) scaleX(-1);
        }
        100% {
          transform: translateX(calc($localGhostWidth + 50px)) scaleX(1);
        }
      }
      @keyframes upAndDown {
        0% {
          bottom: $localGhostBottom;
        }
        50% {
          bottom: calc($localGhostBottom + $localGhostJump);
        }
        100% {
          bottom: $localGhostBottom;
        }
      }
    }

    .ghost_1 {
      $localGhostWidth: 80px;
      $localGhost1Bottom: 10px;
      $localGhost1Jump: 30px;

      z-index: 999 !important;

      width: $localGhostWidth;
      position: absolute;
      animation: ghost1Animation 81s ease-in-out infinite, upAndDown1 1.5s ease-in-out infinite;
      @media screen and (max-width:600px) {
        animation: ghost1Animation 31s ease-in-out infinite, upAndDown1 1.5s ease-in-out infinite;
      }
      bottom: $localGhost1Bottom;
      right: -30px;
      @keyframes ghost1Animation {
        0% {
          transform: translateX(calc($localGhostWidth + 50px)) scaleX(1);
        }
        49% {
          transform: translateX(-149vw) scaleX(1);
        }
        50% {
          transform: translateX(-150vw) scaleX(-1);
        }
        99% {
          transform: translateX(calc($localGhostWidth + 50px)) scaleX(-1);
        }
        100% {
          transform: translateX(calc($localGhostWidth + 50px)) scaleX(1);
        }
      }
      @keyframes upAndDown1 {
        0% {
          bottom: $localGhost1Bottom;
        }
        50% {
          bottom: calc($localGhost1Bottom + $localGhost1Jump);
        }
        100% {
          bottom: $localGhost1Bottom;
        }
      }
    }

    .ghost_2 {
      $localGhostWidth: 80px;
      $localGhost2Bottom: 50px;
      $localGhost2Jump: 20px;
      z-index: 999 !important;

      width: $localGhostWidth;
      position: absolute;
      animation: ghost2Animation 82s ease-in-out infinite, upAndDown2 1.4s ease-in-out infinite;
      @media screen and (max-width:600px) {
        animation: ghost2Animation 32s ease-in-out infinite, upAndDown2 1.4s ease-in-out infinite;
      }
      bottom: $localGhost2Bottom;
      right: -60px;
      @keyframes ghost2Animation {
        0% {
          transform: translateX(calc($localGhostWidth + 50px)) scaleX(1);
        }
        49% {
          transform: translateX(-149vw) scaleX(1);
        }
        50% {
          transform: translateX(-150vw) scaleX(-1);
        }
        99% {
          transform: translateX(calc($localGhostWidth + 50px)) scaleX(-1);
        }
        100% {
          transform: translateX(calc($localGhostWidth + 50px)) scaleX(1);
        }
      }
      @keyframes upAndDown2 {
        0% {
          bottom: $localGhost2Bottom;
        }
        50% {
          bottom: calc($localGhost2Bottom + $localGhost2Jump);
        }
        100% {
          bottom: $localGhost2Bottom;
        }
      }
    }

}
.autumn_animation {
  width: 70% !important;
  padding-left: 15px;
  padding-right: 15px;
  animation: autumnAnimation 4s ease-in-out infinite;
  @keyframes autumnAnimation {
    0% {
      transform: rotate(8deg);
    }
    50% {
      transform: rotate(-5deg);
    }
    100% {
      transform: rotate(8deg);
    }
  }
}
.winter_animation {

  z-index: 1 !important;
  width: 100%;
  height: 100%;
  position: absolute;
  overflow-x: hidden;
  overflow-y: hidden;

  .snowflake {
    --size: 1vw;
    width: var(--size);
    height: var(--size);
    /* background: white; */
    background-image: url("../images/seasonal/snowflake.svg");
    background-size: var(--size);
    /* border-radius: 50%; */
    position: absolute;
    /* box-shadow: 2px 2px #a2a2a2; */
    /* top: -5vh; */
  }
  .leaf_1 {
    background-image: url("../images/seasonal/leaf_1.svg");
    background-repeat: no-repeat;

  }
  .leaf_2 {
    background-image: url("../images/seasonal/leaf_2.svg");
    background-repeat: no-repeat;

  }
  .leaf {
    --size: 4vw;
    width: var(--size);
    height: var(--size);
    /* background: white; */
    background-size: var(--size);
    /* border-radius: 50%; */
    position: absolute;
    /* box-shadow: 2px 2px #a2a2a2; */
    /* top: -5vh; */
    filter: invert(100%);
  }
  @keyframes snowfall {
    0% {
      transform: translate3d(var(--left-ini), -5vh, 0) rotate(-45deg);
      opacity: 1;
    }
    90% {
      opacity: 0;
    }
    100% {
      transform: translate3d(var(--left-end), 110vh, 0) rotate(#{random(90) * 1}deg);
      opacity: 0;
    }
  }
  @keyframes leaffall {
    0% {
      transform: translate3d(var(--left-ini), -5vh, 0);
      opacity: 1;
    }
    20% {
      transform: translate3d(var(--left-ini), 5vh, 0);
    }
    40% {
      transform: translate3d(var(--left-ini), 20vh, 0);
    }
    60% {
      transform: translate3d(var(--left-ini), 40vh, 0);
    }
    80% {
      transform: translate3d(var(--left-ini), 60vh, 0);
    }
    90% {
      transform: translate3d(var(--left-ini), 80vh, 0);
      opacity: 0;
    }
    100% {
      transform: translate3d(var(--left-end), 110vh, 0);
      opacity: 0;
    }
  }
  @for $i from 1 through 80 {
    .snowflake:nth-child(#{$i}) {
      --size: #{random(5) * 0.2}vw;
      @media screen and (max-width:600px) {
        --size: #{random(5) * 0.8}vw;
      }
      --left-ini: #{random(20) - 10}vw;
      --left-end: #{random(20) - 10}vw;
      left: #{random(100)}vw;
      animation: snowfall #{5 + random(10)}s linear infinite;
      animation-delay: -#{random(10)}s;
    }
    .leaf:nth-child(#{$i}) {
      --size: #{random(5) * 1}vw;
      @media screen and (max-width:600px) {
        --size: #{random(6) * 2}vw;
      }
      --left-ini: #{random(20) - 5}vw;
      --left-end: #{random(20) - 5}vw;
      left: #{random(100)}vw;
      animation: leaffall #{5 + random(10)}s linear infinite;
      animation-delay: -#{random(10)}s;
    }
  }

  /* added small blur every 6 snowflakes*/
  .snowflake:nth-child(6n) {
    filter: blur(1px);
  }
}


.loading_screen {
  background-color: white;
  width: 100dvw;
  height: 100dvh;
  position: fixed;
  top: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: loadin_wrap_animation 1s ease-in-out;
  animation-delay: 0.8s;
  overflow: hidden;
  @keyframes loadin_wrap_animation {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-120vh);
    }
  }
  img {
    width: 150px;
    animation: loadin_logo_animation 0.5s ease-in;
    @keyframes loadin_logo_animation {
      0% {
        transform: scale(0);
      }
      60% {
        transform: scale(1.2);
      }
      80% {
        transform: scale(0.8);
      }
      100% {
        transform: scale(1);
      }
    }
  }
}

.blogCard_article{
  min-height: 100vh;
  gap: 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 120px;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  color: #555555;
  .mt-20 {
    margin-top: 20px;
  }
  .mt-50 {
    margin-top: 50px;
  }

  .article_row {

  }

  .article_row_img_main {

  }
  .article_row_img_right {

  }
  .article_row_img_left {

  }
}
.blogCard {
  height: 100vh;
  gap: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 80px;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  color: #555555;

  @keyframes drop_from_top_animation {
    from { transform: translateY(-100vh); }
    to { transform: translateY(0vh); }
  }

  .news_room_title {
    animation: drop_from_top_animation 1.5s ease-in-out;
  }
  .latest_news_wrap {
    .latest_news_card {
      transition: 0.3s;
      cursor: pointer;
      &:hover {
        transform: scale(1.02);
      }
      animation: drop_from_top_animation 1s ease-in-out;
      background-color: rgba(255, 255, 255, 0.886);
      box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.455);
      border-radius: 16px;
      .row {
        display: flex;
        .col_1, .col_2 {
          padding: 30px 25px;
        }
        .col_1 {
          .top_content {
            gap: 15px;
            display: flex;
            flex-direction: column;
            .topic {
              font-size: 16px;
            }
            .title {
              font-size: 24px;
              line-height: 26px;
              font-weight: 500;
            }
            .meta_desr {
              font-size: 16px;
            }
          }
          .bottom_content {

            .date {
              font-size: 14px;
            }
          }
          gap: 60px;
          display: flex;
          flex-direction: column;
          width: 50%;
        }
        .col_2 {
          padding: 0px;
          width: 50%;
          box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.455);
          border-radius: 16px;
          background-color: #966DEC;
        }
      }
    }
  }
}


.add_page_wrap {
  color: #555555;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.cookies_modal_hide {
  @keyframes hide_modal {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(100vh);
    }
  }
  animation: hide_modal 500ms ease-in;
}
.cookies_modal {
  position: fixed;
  z-index: 999;
  bottom: 0px;
  right: 0px;
  width: 80vw;
  max-width: 350px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 20px;
    font-weight: 500;
  }
  .text {
    font-size: 14px;
  }
  .action {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 15px;
    font-size: 16px;
    .cookies_redirect {
      color: #414141;
      transition: 0.3s;
      text-decoration: underline;
      cursor: pointer;
      &:hover {
        color: #7038E4;
      }
    }

    .agree_button {
      cursor: pointer;
      border: 1px solid #414141;
      padding: 5px 10px;
      border-radius: 10px;
      transition: 0.3s;
      &:hover {
        background-color: #7038E4;
        color: #ffffff;
        border-color: #7038E4;
      }
    }
  }

  border-radius: 16px;
  background-color: rgba(255, 255, 255, 0.886);
  box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.455);
  margin: 20px 15px;
  transition: 0.5s;
  padding: 20px;
  color: #414141;
  img {
    width: 30px;
  }
}
.cookies_page {
  .cancel_cookies_wrap {
    display: flex;
    div {
      cursor: pointer;
      border: 1px solid #414141;
      padding: 5px 15px;
      border-radius: 10px;
      transition: 0.3s;
      &:hover {
        background-color: #7038E4;
        color: #ffffff;
        border-color: #7038E4;
      }

    }
  }
  .title {
    display: flex;
    justify-content: space-between;
    img {
      width: 50px;
    }
  }
  a {
    color: #7038E4;
  }
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-top: 100px;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  color: #414141;
  .card {
    border-radius: 16px;
    background-color: rgba(255, 255, 255, 0.886);
    box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.455);
    margin: 20px 15px;
    transition: 0.5s;
    padding: 20px;
  }
}