.contactCard {
  padding: 0px 15px;
  display: flex;
  flex-direction: column;
  color: #555555 !important;

  width: 100vw;
  max-width: 800px;
  padding-bottom: 150px;

  margin-right: auto;
  margin-left: auto;

  align-items: center;
  @media only screen and (max-width: 768px) {
   padding: 0px 0px;
   width: 95vw;
  }
  h1 {
    margin-top: 100px;
    font-size: 80px;
    line-height: 60px;
  }
  h3 {
    text-align: center;
  }
  @media only screen and (max-width: 768px) {
    /* Styles for medium devices */

    padding-bottom: 40px;
    h1 {
      margin-top: 100px;
    }
  }
  .contactCard__more_button {

    -moz-box-shadow: 0 0 18px #555555;
    -webkit-box-shadow: 0 0 18px #555555;
    box-shadow: 0 0 18px #555555;
    background-color: rgba($color: #ffffff, $alpha: 0.6);
    border-radius: 12px;
    .link {
      padding: 0px !important;
      margin: 0px !important;
      font-size: 30px;
      transition: 0.3s;
      -webkit-user-select: none; /* Safari */
      -ms-user-select: none; /* IE 10 and IE 11 */
      user-select: none; /* Standard syntax */
    }
    cursor: pointer;
    transition: 0.3s;
    border: 2px solid white;
    border-radius: 8px;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
    padding: 15px 60px 15px 60px;
    font-weight: 500;
    font-size: 20px;
    &:hover {
      box-shadow: 8px 8px 2px 1px rgba($purple_color, 1);
      .link {

        color: #5E45D6;
      }
      background-color: white;

    }
    &:active {
      box-shadow: 0px 0px 2px 1px rgba($purple_color, 1);
    }
  }
}
.contactCard__contact_wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  .only_on_mobile {
    display: none;
  }
  a {
    text-decoration: none !important;
    color: #555555;
    transition: 0.3s;
    &:hover {
      color: #5E45D6;
    }
  }
  text-align: center;
  h2 {
    font-size: 40px;
    line-height: 55px;
    margin-top: 0px;
    font-weight: 400;
    a {
      margin-left: 30px;
      font-size: 50px;
      font-weight: 600;
    }
  }
  div {
    display: flex;
    gap: 20px;
    justify-content: center;
    margin-bottom: 20px;
    .icon {
      cursor: pointer;
      transition: 0.3s;
      &:hover {
        color: #555555;
      }
    }
  }
  h2:nth-child(1) {
    // transform: rotate(-15deg);
  }
  h2:nth-child(2) {
    //transform: rotate(5deg);
  }
  h2:nth-child(3) {
    //transform: rotate(15deg);
  }

  @media only screen and (max-width: 768px) {
    .only_on_mobile {
      display: block;
    }
    margin-top: 0px;
    h2 {
      a {
        margin-left: 0px;
        font-size: 30px;
        line-height: 35px;
        font-weight: 700;
      }
    }
  }
}


.moreContactCard {


  height: 100vh;
  gap: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;


  color: #555555;
  h2 {
    a {
      text-decoration: none;
      color: #555555;
      transition: 0.3s;
      &:hover {
        color: #5E45D6;
      }
    }
  }
  .socials {
    display: flex;
    gap: 20px;
    .icon {
      cursor: pointer;
      transition: 0.3s;
      &:hover {
        color: #5E45D6;
      }
    }
  }
  div {
    border-radius: 8px;
    min-width: 30vw;
    padding: 0px 20px;
    margin-right: auto;
    margin-left: auto;
    div {
      /* background: -webkit-linear-gradient(left, $green_color, $purple_color);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent; */
      h2 {
        font-weight: 500;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    div {
      width: 80vw;
      div {
        h2 {
          line-height: 25px;
        }
      }
    }
  }
}