.serviceCard {
  padding: 0px 15px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  color: #555555 !important;


  width: 100vw;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;

  @media only screen and (max-width: 768px) {
   padding: 0px 0px;
   width: 95vw;
  }

  align-items: center;
  h1 {
    margin-top: 0px;
    // font-size: xxx-large;
    font-size: 80px;
    line-height: 60px;
  }
  h2 {
    font-weight: 500;
    text-align: center;
    font-size: 50px;
    line-height: 50px;
  }
  h3 {
    font-weight: 500;
    text-align: justify;

    font-size: 20px;
    line-height: 30px;
    margin-bottom: 50px;
    padding-left: 30px;
    padding-right: 30px;
  }
  .about_card {

    -moz-box-shadow: 0 0 18px #555555;
    -webkit-box-shadow: 0 0 18px #555555;
    box-shadow: 0 0 18px #555555;
    background-color: rgba($color: #ffffff, $alpha: 0.6);
    border-radius: 12px;
    padding-top: 15px;
  }
  @media only screen and (max-width: 768px) {
    /* Styles for medium devices */
    h1 {
      margin-top: 50px;
    }
    h3 {
      padding-left: 25px;
      padding-right: 25px;
    }
  }
}
.serviceCard__benefit_list_wrap {
  width: 120%;
  margin-top: 50px;


  @media only screen and (max-width: 768px) {
    padding: 0px 0px;
    width: 95vw;
   }

  .benefit_row {
    display: flex;
    &:nth-child(even) {
      flex-direction: row-reverse;
    }
  }
  .element {
    margin-bottom: 20px;
    padding: 20px 40px;
    display: flex;
    gap: 40px;
    align-items: center;

    @media only screen and (max-width: 768px) {
      padding: 10px 20px;
      gap: 5px;
      margin-bottom: 30px;
      h2 {
        padding: 0px 0px 10px 0px;
        margin: 0px;
      }
    }
    div {
      p {
        padding: 0px 0px 0px 0px;
        margin: 0px;
      }
      h2 {
        padding: 0px 0px 10px 0px;
        margin: 0px;
      }
    }

    -moz-box-shadow: 0 0 18px #555555;
    -webkit-box-shadow: 0 0 18px #555555;
    box-shadow: 0 0 18px #555555;
    background-color: rgba($color: #ffffff, $alpha: 0.6);
    border-radius: 12px;
    padding-top: 25px;
    max-width: 580px;


    &:hover {
      .icon {
        transition: 0.3s;
        color: #5E45D6;
      }
    }

    h2 {
      text-align: start;
      margin-top: 0px;
      font-weight: 500;
      font-size: 50px;
      line-height: 40px;
    }
    @media only screen and (max-width: 768px) {
      margin-bottom: 10px;
      flex-direction: column;
      div {
        order: 1 !important;
      }
      h2 {
        text-align: center;
        font-size: 36px;
      }
    }
    @media only screen and (max-width: 375px) {
      h2 {
        text-align: center;
        font-size: 36px;
      }
    }
    p {
      font-size: 17px
    }
  }
}
.serviceCard__layouts_wrap {
  text-align: center;
  flex-direction: column;
  display: flex;
  align-items: center;
  flex-direction: column;
  /* margin-bottom: 50px; */
  margin-top: 40px;
  h2 {
    z-index: 10;
  }
  img {
    max-width: 500px;
    z-index: 5;
    transition: 0.3s;
    &:hover {
      transform: scale(1.1);
    }
  }

  @media only screen and (max-width: 768px) {
    h2 {
      max-width: 300px;
    }
    img {
      max-width: 80%;
      &:hover {
        transform: scale(1.1);
      }
    }
  }
}
.serviceCard__timeline {
  width: 140%;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
  .timeline_row {
    -moz-box-shadow: 0 0 18px #555555;
    -webkit-box-shadow: 0 0 18px #555555;
    box-shadow: 0 0 18px #555555;
    background-color: rgba($color: #ffffff, $alpha: 0.6);
    border-radius: 12px;
    text-align: start;
    max-width: 700px;
    img {
      width: 100px !important;
    }

    @media only screen and (max-width: 768px) {
      margin-left: 0px !important;
      gap: 10px;
      max-width: 100%;
    }
    div:nth-child(1) {
      display: flex;
      align-items: center;
      justify-content: center;
      @media only screen and (max-width: 768px) {
        min-width: 80px;
      }
    }
    display: flex;
    gap: 30px;
    padding: 10px 30px;
    padding-bottom: 15px;
    margin-bottom: 26px;
    @media only screen and (max-width: 768px) {
      padding: 10px 10px;
    }
    h2 {
      font-size: 30px;
      text-align: start;
      margin: 0;
      @media only screen and (max-width: 768px) {

      line-height: 30px;
      }
    }
    h3 {
      font-size: 20px;
      margin: 0;
      padding: 0;
    }
    p {
      margin: 0;
      font-size: 18px;
      @media only screen and (max-width: 768px) {
        line-height: 22px;
        padding-top: 10px;
      }
    }
    a {
      text-decoration: underline;
      cursor: pointer;
    }
    .socials {
      display: flex;
      gap: 12px;
      margin-top: 10px;
      .icon {
        color: #555555;
        cursor: pointer;
        transition: 0.3s;
        &:hover {
          transform: scale(1.05);
        }
      }
    }
  }
}


