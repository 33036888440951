.client_list_wrap{
  margin-top: 5px;
}
.w-100 {
  width: 100%;
}
.w-75 {
  width: 75%;
}
.w-50 {
  width: 50%;
}
.w-25 {
  width: 25%;
}
.row {
  display: flex;
  flex-direction: row;
}
.menu_element{


  position: relative;
  .invoice_alert {
    position: absolute;
    width: 10px;
    height: 10px;
    bottom: 2px;
    right: -2px;
    border-radius: 100%;
    background-color: #DC3C3C;
    border: 1.5px solid #fff;
  }
}
.alert_component {
  box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.455);
  position: absolute;
  top: -100px;
  right: 15px;
  transition: 0.4s;
  z-index: 999 !important;
  color: #2C2C2C;
  padding: 10px 15px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background-color: rgba(255, 255, 255, 1) !important;

  .icon{
    display: flex;
  }
  .text {
  }
}

.admin_page {

  @media only screen and (max-width: 1000px) {
    display: flex;
    justify-content: center;
    align-items: center;
    .logout_card {
        display: flex;
    }
  }

  .align-center {
    display: flex;
    align-items: center;
  }
  .how_to {
  }

  .copy_icon {
    color: #2c2c2c8a;
    margin-left: 5px;
    transition: 0.3s;
    cursor: pointer;
    &:hover {
      color: #6134c2;
    }
  }
  input {
    background-color: transparent;
  }
  .dragged_over_item {
    background-color: orange !important;
  }
  .scroll_indicator {
    width: 6px;
    height: 20px;
    background-color: #6A39E2;
    border-radius: 16px;
    position: absolute;
    right: 10px;
    top: 15px;
  }
  /* .scroll_indicator_item_list {

    width: 6px;
    height: 52px;
    background-color: #6A39E2;
    border-radius: 16px;
    position: absolute;
    right: 0px;
    top: 136px;
  } */
  .remove_image_icon {
    position: absolute;
    bottom: 10px;
    right: 10px;
    transition: 0.3s;
    &:hover {
      transform: scale(1.1);
    }
  }
  .layout_wrap {
    display: flex;
    justify-content: space-between;
    gap: 15px;
    .layout_elements_wrap {

      /* overflow-x: scroll; */
      max-height: calc(100vh - 170px);
      height: 100%;
      /* height: calc(100vh - 170px); */
      /* overflow-x: scroll;
      height: calc(100vh - 130px); */
      /* background-color: red; */
      margin: 0 !important;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      width: 75%;
      gap: 15px;
      .offer_block_card_dark {
        border-radius: 16px;
        background-color: rgba(255, 255, 255, 0.886);
        box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.455);
        margin: 20px 15px;
        transition: 0.5s;
        padding: 15px;
        ::selection {
          color: white;
          background: #2c2c2c89;
        }
        background-color: #2c2c2c67;
        color: white;
        &:hover {
          background-color: #2c2c2c89;
          &:active {
            transform: scale(1);
          }
        }
      }
      .offer_block_card_disabled {
        cursor: default !important;
        &:active {
          cursor: default !important;
        }
      }
      .offer_block_card {
        position: relative;
        .edit_section_icon_hidden {
          transform: scale(0) !important;
        }
        .edit_section_icon {
          transform: scale(1);
          position: absolute;
          top: 5px;
          right: 5px;
          transition: 0.3s;
          cursor: pointer;

        }
        .remove_section_icon_hidden {
          transform: scale(0) !important;
        }
        .selected_section {
          color: #8E63EA;
          position: absolute;
          bottom: -7px;
          right: -8px;

        }
        .hidden_section {
          color: #ec3c3c;
          position: absolute;
          bottom: -7px;
          right: 14px;

        }
        .remove_section_icon {
          transform: scale(1);
          position: absolute;
          top: 5px;
          left: 5px;
          transition: 0.3s;
          cursor: pointer;

        }
        transition: 0.3s;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 5px;
        height: 70px;
        min-width: 90px;
        width: auto;
        cursor: grab;
        &:active {
          cursor:grabbing;
        }
        &:hover {

        }
        img {
          width: 40px;
          max-height: 45px;
          pointer-events: none !important;
          user-select: none !important;
        }
        span {
          text-align: center;
          font-size: 15px;
          line-height: 15px;

          display: inline-block;
          /* max-width: 15ch; */ /* Obmedzenie na približne 15 znakov */
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
    .layout_preview_wrap {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      width: 25%;
      min-width: 260px;
      flex-direction: column;
      .action_bar {
        width: 25%;
        min-width: 250px;
        padding-top: 5px;
        display: flex;
        gap: 15px;
        .action {
          display: flex;
          align-items: center;
          white-space: nowrap;
          font-size: 14px;
          gap: 5px;
          transition: 0.3s;
          cursor: pointer;
          &:hover {
            color: #6134c2;
          }
        }
      }
    }
  }
  .item_preview_wrap {
    position: relative;
    width: 50px !important;
    background-color: #6d6d6dae !important;
    color: white;
    &:hover {
      transform: scale(1.05);
      background-color: #6d6d6de4;
    }
    .item_preview {
      max-height: "140px";
      width: "auto";
      object-fit: contain;
    }
  }
  button {
    margin-top: 25px;
    border: 2px solid white;
    background: rgb(114,59,229);
background: linear-gradient(105deg, rgba(114,59,229,1) 0%, rgba(188,162,242,1) 100%);
    color: white;
    border-radius: 8px;
    padding: 5px 15px;
    transition: 0.3s;
    cursor: pointer;
    font-weight: bold;
    font-size: 22px;
    &:hover {
      transition: 0.3s;
      transform: scale(1.02);
    }
    &:active {
      font-size: 20px;
    }
  }
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100vh;

  $local_purple: #7945E6;
  $local_purple_dark: #6A39E2;
  $local_black: #2C2C2C;
  $local_border_color: #A4A4A4;

  .move_items_wrap {
    color: $local_black;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    span {
      white-space: nowrap;
      width: 0px;
      overflow: hidden;
      transition: 0.2s;
    }
    &:active {
      transform: scale(0.98);
    }
    &:hover {
      span {
        width: 120px;
      }
    }
  }

  .no_offer_blocks_info {
    margin-top: 25px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: $local_black;
    align-items: center;
    font-size: 22px;
    font-weight: 500;
    padding-left: 20px;
    padding-right: 20px;
    animation: localAnimation 0.3s ease-in-out;
    @keyframes localAnimation {
      0% {
        scale: 0;
      }
      100% {
        scale: 1;
      }
    }
  }

  .not_available {
    min-height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 30px;
    img {
      max-width: 200px;
    }
  }
  .title {
    font-size: 28px;
    line-height: 28px;
    font-weight: 600;
  }
  .subscribtion_journey {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 10px;
    padding-top: 10px !important;
    progress {
      transition: width 0.3s ease-in-out; /* Pridáme animáciu na šírku */
      width: 100%;
      height: 10px; /* nastaví výšku */
      appearance: none; /* odstráni predvolené štýly pre niektoré prehliadače */
      border-radius: 18px;
    }
    progress::-webkit-progress-bar {
      border-radius: 18px;
      background-color: #e0e0e0; /* farba pozadia baru */
    }
    progress::-webkit-progress-value {
      transition: width 0.3s ease-in-out; /* Animácia šírky */
      border-radius: 18px;
      background-color: #9A73EC; /* farba hodnoty progresu */
    }

    progress::-moz-progress-bar {
      transition: width 0.3s ease-in-out; /* Animácia šírky */
      border-radius: 18px;
      background-color: #9A73EC; /* pre Firefox */
    }
    .progress_unpaid {
      &::-moz-progress-bar {
        transition: width 0.3s ease-in-out; /* Animácia šírky */
        border-radius: 18px;
        background-color: #BD282B ; /* pre Firefox */
      }
      &::-webkit-progress-value {
        transition: width 0.3s ease-in-out; /* Animácia šírky */
        border-radius: 18px;
        background-color: #BD282B ; /* farba hodnoty progresu */
      }
    }
    .progress_alert {
      &::-moz-progress-bar {
        transition: width 0.3s ease-in-out; /* Animácia šírky */
        border-radius: 18px;
        background-color: #FFCE08 ; /* pre Firefox */
      }
      &::-webkit-progress-value {
        transition: width 0.3s ease-in-out; /* Animácia šírky */
        border-radius: 18px;
        background-color: #FFCE08 ; /* farba hodnoty progresu */
      }
    }
  }
  .progress_wrapper {
    width: 100%;
    height: 10px; /* nastaví výšku */
    border-radius: 18px;

  }
  .progress_container {
    height: 10px;
    /* background-color: #6A39E2; */
    border-radius: 18px;
  }

  .progress_label {
    display: none;
  }
  .subtitle {
    font-size: 20px;
    line-height: 24px;
    font-weight: 450;
  }
  .subscription_period_dates {
    /* font-size: 15px;
    padding-left: 15px; */

  }
  .supplier_offer_wrap {
    max-height: 80vh;
    overflow: scroll;
  }
  .supplier_offer {
    display: flex;
    align-items: flex-start;
    gap: 15px;
    padding-left: 0px !important;
    padding-bottom: 0px !important;
    padding-top: 0px !important;
    overflow: hidden;
    @media only screen and (max-width: 1400px) {
      display: flex;
      padding-right: 0px !important;
      flex-direction: column !important;
      img {
        width: 100%;
        object-fit: cover;
        /* max-width: 400px; */
        border-radius: 0px !important;
      }
      .title_section {
        margin: 0px 15px;
        overflow: hidden;
        max-width: none !important;
        width: calc(100% - 30px);
        margin-bottom: 15px;
      }
    }

    .title_section {
      display: flex;
      flex-direction: column;
      gap: 8px;
      position: relative;
      max-width: 40vw;
      overflow: hidden;
      .company_name {
        font-size: 26px;
        font-weight: 500;
      }
      .descr {
        /* white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; */
        white-space: nowrap;         /* Zakáže zalamovanie textu */
        position: relative;
        width: 100%;      /* Pre animáciu */

        /* Animácia */
        &:hover {
          animation: scroll-text 10s linear infinite; /* 10s cyklus, nekonečne */

        }
        @keyframes scroll-text {
          0% {
            transform: translateX(0%); /* Začiatok textu mimo obrazovku (vpravo) */
          }
          100% {
            transform: translateX(-100%); /* Text sa posunie úplne mimo obrazovku (vľavo) */
          }
        }
      }
      .title {
        font-size: 22px;
        font-weight: 450;
      }
      .contact {
        display: flex;
        align-items: center;
        gap: 15px;
        span {

          display: flex;
          align-items: center;
          gap: 5px;
          transition: 0.3s;
          &:hover {
            color: #6134c2;
          }
        }
      }
    }
    img {
      max-height: 190px;
      /* max-width: 400px; */
      border-radius: 16px 0px 0px 16px;
    }
  }


  .invoice_item_element {
    padding: 10px 15px !important;
    border-radius: 8px !important;
    transition: 0.3s;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .invoice_period {
      display: flex;
      align-items: center;
      gap: 5px;
      color: #2C2C2C;
    }
    .invoice_status {
      padding: 1px 15px;
      border-radius: 6px;
      display: flex;
      align-items: center;
      gap: 10px;
      font-size: 14px;
      white-space: nowrap;
    }
    .invoice_status_paid {
      background-color: #18BC27;
      color: white;
    }
    .invoice_status_pending {
      background-color: #DC3C3C;
      color: white;
    }
    .invoice_status_overdue {
      background-color: #FF810C;
      color: white;
    }
    .inner_wrap_1{
      display: flex;
      align-items: center;
      gap: 10px;
      .invoice_open {
        cursor: pointer;
        display: flex;
        align-items: center;
        transition: 0.3s;
        color: #2C2C2C;
        &:hover {
          transform: scale(1.05);
          color: #6A39E2 ;
        }
      }
    }
    &:hover {
      transform: translateX(8px)
    }
  }
  .invoice_list_wrap {
    display: flex;
    justify-content: space-between;
    width: 100%;
    .transparent_card.invoice_list_holder {
      border-radius: 0px;
      margin-left: 0px;
      margin-right: 0px;
    }
    .invoice_list_holder {
      width: 60%;
      position: relative;
      .card {
        margin: 0px !important;
        margin-bottom: 15px !important;
      }
      .title {
        padding: 10px 15px !important;
        padding-bottom: 0px !important;
        color: #2C2C2C;
      }
    }
    .no_invoices {
      display: flex;
      align-items: center;
      gap: 10px;
    }
    .invoice_list {
      margin-left: 0;
      margin-right: 0;
      height: 85%;
      overflow-y: scroll;
      border-radius: 0px;
      padding: 10px 15px !important;
      padding-top: 0px !important;
      .card {
        margin-top: 5px !important;
      }
    }
    .invoice_preview {
      display: flex;
      text-align: center;
      width: 40% ;
    }
  }
  .subscription_period_card {
    width: 100%;
  }
  .subscription_period {
    font-weight: 500;
    color: #6A39E2;
  }
  .sunscription_info_wrap {
    .pay_me_button {
      margin-top: 0px;
      margin-left: 0px;
      margin-right: 0px;
      margin-bottom: 10px;
      border-radius: 12px;
      padding-bottom: 12px;
      padding-top: 12px;

      display: flex;
      align-items: center;
      gap: 5px;
      justify-content: space-between;
      text-align: center;
      transition: 0.3s;
      cursor: pointer;
      &:hover {
        background: #6134c2;
        color: #fff;
      }
    }
  }
  .subscription_info {
    display: flex;
  }
  .add_after_element_selector_wrap {
    max-width: 350px;
  }
  .selector_element_wrap {
    background-color: rgba(255, 255, 255, 0.886) !important;
    box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.455);
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    .icon {
      margin-right: 10px;
    }
    position: relative;
    .selector {
      padding: 6px 10px;
      min-width: 150px !important;
      width: calc(100% - 40px);
      height: 100%;
      cursor: pointer;
      display: flex;
      align-items: center;
      padding-left: 10px;
      span {
        overflow: hidden;

        white-space: nowrap;
        animation: selectorAnimation 0.7s ease-in-out;
        @keyframes selectorAnimation {
          0% {
            width: 0px;
          }
          100% {
            width: 100%;
          }
        }
      }
    }
    .selector_list_open {
      max-height: 200px !important;
      min-height: 50px !important;
      padding-bottom: 10px !important;
      padding-top: 5px !important;

    }

    .selector_list {
      border-radius: 12px;
      /* padding: 10px 0px 10px 10px !important; */
      background-color: rgba(255, 255, 255, 0.886);
      box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.455);
      transition: 0.3s;
      overflow-y: scroll;
      max-height: 0px;
      width: 100%;
      top: 40px;
      left: 0px;
      z-index: 900;
      position: absolute;
      .selector_element {
        padding: 5px 0px 0px 10px !important;
        cursor: pointer;
        padding-top: 5px;
        &:hover {
          color: $local_purple_dark;
          font-weight: 500;
        }
      }
      .selector_element_active {
        color: $local_purple_dark;
      }
    }
  }
  .create_element_button_hidden {
    bottom: -100px !important;
  }
  .create_offer_block_button_wrap {
    animation: create_button_load_animation 0.8s ease-in-out;
    @keyframes create_button_load_animation {
      from {
        bottom: -100px;
      }
      to {
        bottom: 25px;
      }
    }
    transition: 0.3s;
    color: $local_black;
    display: flex;
    align-items: center;
    z-index: 990;
    gap: 5px;

    span {
      max-width: 0px;
      transition: 0.3s;
      overflow: hidden;
      white-space: nowrap;
    }

    .create_element_button {
      width: 45px;
      height: 45px;
      transition: 0.3s;
      background: $local_purple;
      background: linear-gradient(105deg, $local_purple 0%, rgba(188,162,242,1) 100%);
      color: white;
      z-index: 990;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      cursor: pointer;

      &:hover {
        transform: scale(1.05);
      }
    }

    &:hover {
      .create_element_button {
        transform: scale(1.05);
      }

      span {
        max-width: 500px;
      }
    }
  }
  .create_element_button_wrap {

    @media only screen and (max-width: 1000px) {
      display: none;
    }
    animation: create_button_load_animation 0.8s ease-in-out;
    @keyframes create_button_load_animation {
      from {
        bottom: -100px;
      }
      to {
        bottom: 25px;
      }
    }
    position: absolute;
    bottom: 25px;
    /* right: 420px; */
    right: 25px;
    transition: 0.3s;
    color: $local_black;
    display: flex;
    align-items: center;
    gap: 10px;
    z-index: 990;

    span {
      max-width: 0px;
      transition: 0.3s;
      overflow: hidden;
      white-space: nowrap;
    }

    .create_element_button {
      width: 45px;
      height: 45px;
      transition: 0.3s;
      background: $local_purple;
      background: linear-gradient(105deg, $local_purple 0%, rgba(188,162,242,1) 100%);
      color: white;
      z-index: 990;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      cursor: pointer;

      &:hover {
        transform: scale(1.05);
      }
    }

    &:hover {
      .create_element_button {
        transform: scale(1.05);
      }

      span {
        max-width: 500px;
      }
    }
  }
  .checkbox_wrap {
    display: flex;
    gap: 10px;
    align-items: center;
  }
  .checkbox {
    border-radius: 8px;
    padding: 10px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.3s;
    &:hover {
      transform: scale(1.05);
    }
  }
  .create_element_modal {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .modal {
      /* min-width: 670px !important; */

    }
    .admin_flag_preview {
      padding-top: 10px;
      cursor: pointer;
      transition: 0.3s;
      &:hover {
        img {
          filter: none;
          transform: scale(1.05);
        }
      }
      img {
        max-width: 72px;
        padding-right: 5px;
        transition: 0.3s;
        filter: grayscale(100%);
      }
      .flag_selected {
        filter: none;
      }
    }
    .item_icon_card {
      z-index: 500;
      transition: 0.3s;
      /* width: 735px; */
      border-radius: 16px;
      color: #2C2C2C;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      gap: 15px;
      .icon {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        background-color: #6d6d6dae;
        padding: 5px 5px;
        border-radius: 12px;
        transition: 0.3s;
        width: 40px;
        height: 40px;
        img {
          width: 40px;
        }
        &:hover {
          transform: scale(1.05);
          background-color: #6d6d6de4;
        }
      }
    }



    .offer_block_icon_card_visible {
      min-height: 100px;
      padding: 35px 45px;
      transform: translateY(0px);
      opacity: 1;
    }
    .offer_block_icon_card_hidden {
      transform: translateY(-10px);
      overflow: hidden;
      height: 0px;
      min-height: 0px;
      padding: 0px 45px;
      opacity: 0;
    }
    .selected_icon_offer_block {
      background-color: #6d6d6dae;
      color: white;
      &:hover {
        transform: scale(1.05);
        background-color: #6d6d6de4;
      }
      img {
        width: 60px !important;
      }
    }
    .offer_block_icon_card {
      scroll-behavior: smooth;
      z-index: 500;
      transition: 0.3s;
      width: 462px;
      max-height: 100px;
      overflow: scroll;
      border-radius: 16px;
      color: #2C2C2C;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: space-between;
      gap: 7px;
      .offer_block_icon {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        background-color: #6d6d6dae;
        padding: 15px 15px;
        border-radius: 16px;
        transition: 0.3s;
        img {
          width: 40px;
        }
        &:hover {
          transform: scale(1.05);
          background-color: #6d6d6de4;
        }
      }
    }
    .modal {
      align-items: flex-start !important;
      justify-content: flex-start !important;
      max-width: none !important;
      min-width: none !important;
      .button {
        max-width: none !important;
        &:active {
         font-size: 18px !important;
         outline: 1px solid $local_purple;
        }
      }
      .content {
        .error_message {
          font-size: 14px;
          color: rgb(192, 8, 8);
          font-weight: 300;
        }
        .title_row {
          font-size: 24px;
          font-weight: 600;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .icon {
            cursor: pointer;
            transition: 0.3s;
            color: #2C2C2C;
            &:hover {
              color: #6134c2;
              transform: scale(1.05);
            }
          }
          /* border-bottom: 1px solid $local_border_color; */
          /* padding-bottom: 10px; */
          /* margin-bottom: 10px; */
        }
        .section_row {
          border-bottom: 1px solid $local_border_color;
          padding-bottom: 10px;
          /* padding-top: 10px; */
        }
        .fields_wrap {
          display: flex;
          gap: 20px;

          .extended_section_visible {
            max-width: 300px !important;

            padding-left: 10px;
          }
          .extended_section_hidden {

          }
          .extended_section {
            max-width: 0px;
            transition: 0.3s;
            overflow: hidden;
            .checkbox_wrap {
              min-width: 230px;

            }
            .field_element {
              min-width: 230px;
              .description_card_element {
                width: 85%;
                textarea {
                  width: 250px !important;
                }
              }
            }
          }

          .fields_col_1 {
            .field_element {
              .field {
                position: relative;
                .clear_input {
                  cursor: pointer;
                  color: #848484;
                  position: absolute;
                  z-index: 900;
                  background-color: white;
                }
                width: auto;
                &:focus-within {
                  box-shadow: 1px 2px 8px rgba(#6134c2, 1);
                }
              }
            }
          }
          .fields_col_2 {
            .field {
              position: relative;
              .clear_input {
                cursor: pointer;
                color: #848484;
                position: absolute;
                z-index: 900;
                background-color: white;
              }

              &:focus-within {
                box-shadow: 1px 2px 8px rgba(#6134c2, 1);
              }
            }
            .row_1 {
              display: flex;
              flex-direction: row;
              gap: 15px;
              .field_element {
                width: 120px;
                position: relative;
                .clear_input {
                  cursor: pointer;
                  color: #848484;
                  position: absolute;
                  z-index: 900;
                  background-color: white;
                }
                .field {
                  &:focus-within {
                    box-shadow: 1px 2px 8px rgba(#6134c2, 1);
                  }
                }
                .price_field, .volume_field {
                  padding: 10px 10px;
                  border-radius: 10px;
                  display: flex;
                  &:focus-within {
                    box-shadow: 1px 2px 8px rgba(#6134c2, 1);
                  }

                  input {
                    width: 100%;
                    border: none;
                    &:focus {
                      border: none;
                      outline: none;
                    }
                  }
                }
              }
            }
            .row_2 {
              margin-top: 34px;
              display: flex;
              flex-direction: column;
              gap: 7px;

            }
          }
          .fields_col_3 {
            .field {
              &:focus-within {
                box-shadow: 1px 2px 8px rgba(#6134c2, 1);
              }
            }
            .field_element:nth-child(2) {
              label {
                visibility: hidden;
              }
              height: calc(100% - 100px);
              .field {
                &:focus-within {
                  box-shadow: 1px 2px 8px rgba(#6134c2, 1);
                }
                cursor: pointer;
                transition: 0.3s;
                .icon {
                  transition: 0.3s;
                }
                &:hover {
                 /*  transform: scale(1.02); */
                 .icon {
                    /* transform: scale(1.1); */
                 }
                }
                img {
                  width: 110px;
                  border-radius: 8px;
                }
                height: 100% !important;
                flex-direction: column;
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
          }
          .field_element {
            margin-top: 10px;
            .description_card_element_disabled {
              opacity: 0.2;
            }
            .description_card_element {

              textarea {
                border: none;
                max-width: 190px;
                resize: none;
                &:focus {
                  border: none;
                  outline: none;
                }
              }
            }
            .field {
              padding: 10px 15px;
              border-radius: 12px;
              min-width: 180px;
              width: 60%;
              display: flex;
              align-items: center;
              justify-content: space-between;
              input {
                width: 100%;
                border: none;
                &:focus {
                  border: none;
                  outline: none;
                }
              }
            }
          }
        }
      }
    }
  }
  .select_flag_wrap {
    /* display: flex;
    justify-content: space-between; */
    min-width: 220px;
    /* padding-bottom: 5px; */
    display: flex;
    .checkbox_wrap {
      min-width: 50px !important;
    }
  }
  .confirmation_modal {
    position: absolute;
    z-index: 998;
    width: 100vw;
    height: 100vh;
    background-color: #00000091 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    .modal {
      padding: 35px 45px;
      transition: 0.3s;
      color: $local_black;
      background-color: rgba(255, 255, 255, 0.886);
      border-radius: 16px;
      max-width: 350px;
      min-width: 300px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      position: relative;
      animation: localAnimation 0.3s ease-in-out;
      @keyframes localAnimation {
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }

      .close_icon {
        position: absolute;
        right: 10px;
        top: 10px;
        cursor: pointer;
        transition: 0.3s;
        &:hover {
          transform: scale(1.1);
        }
      }
      .text {
        text-align: center;
      }
      .button {
        width: 100%;
        max-width: 180px;
        margin-top: 10px;
        border: 2px solid white;
        background: $local_purple;
        background: linear-gradient(105deg, $local_purple 0%, rgba(188,162,242,1) 100%);
        color: white;
        border-radius: 8px;
        padding: 8px 5px;
        transition: 0.3s;
        cursor: pointer;
        font-weight: bold;
        font-size: 18px;
        &:hover {
          transition: 0.3s;
          transform: scale(1.02);
        }
        &:active {
          font-size: 18px !important;
          outline: 1px solid $local_purple;
         }
      }
    }
  }
  .card_element {
    background-color: rgba(255, 255, 255, 0.886);
    box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.455);
  }
  .card_transparent {
    animation: opening_middle_card_animation 0.5s ease-in-out;
    margin: 20px 15px;
    transition: 0.5s;
    color: $local_black;
  }
  .layout_edit_title_card {
    display: flex;
    justify-content: space-between;
    .action {
      display: flex;
      gap: 20px;
      .action_element {
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 5px;
        transition: 0.3s;
        &:hover {
          transform: translateX(-5px);
        }
      }
    }
  }
  .justify-between {
    justify-content: space-between;
  }
  .profile_col {
    width: 50%;
    .row {

    }
    .col {

    }
  }
  .profile_row {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* gap: 25px; */
    /* padding: 15px 0px; */
    .primary_text {
      font-size: 22px;
      font-weight: 500;
    }
    .secondary_text {
      white-space: nowrap;
      font-size: 18px;
    }
    .phone_number {
      display: flex;
      gap: 10px;
      align-items: center;
    }

    .row_name_phone {
      display: flex;
      justify-content: space-between;
    }
    .row_email {
      display: flex;
      justify-content: flex-end;
    }

  }
  .margin-sides-0 {
    margin: 20px 0px !important;
  }
  .transparent_card {
    animation: opening_middle_card_animation 0.5s ease-in-out;

    border-radius: 16px;
    margin: 20px 15px;
    transition: 0.5s;
    padding: 0px;
    color: $local_black;
  }
  .card {
    animation: opening_middle_card_animation 0.5s ease-in-out;

    border-radius: 16px;
    background-color: rgba(255, 255, 255, 0.886);
    box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.455);
    margin: 20px 15px;
    transition: 0.5s;
    padding: 20px;
    color: $local_black;
  }

  .nav_card {

    @media only screen and (max-width: 1000px) {
      display: none;
    }
    margin-bottom: 20px !important;
    height: 100%;
    /* LOGO SECTION */

    .settings_element {
      padding: 5px 0px;
      display: flex;
      align-items: center;
      transition: 0.3s;
      gap: 8px;
      cursor: pointer;
      &:hover {
        color: $local_purple_dark;
      }
    }
    .logo_wrap {
      @keyframes rotate-animation {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
      }
      position: relative;
      .loading_wrap {
        visibility: hidden;
        transition: 0.3s;
        position: absolute;
        right: -15px;
        top: -15px;
        font-size: 14px;
        line-height: 12px;
        display: flex;
        gap: 5px;
        align-items: flex-start;
        .loading_icon_local {
          animation: 1s rotate-animation infinite linear;
        }
      }
      .client_logo {
        width: 55px;
      }
      .logo_bg {
        position: relative;
        background-color: $local_purple;
        border-radius: 100%;
        width: 90px;
        height: 90px;
        display: flex;
        justify-content: center;
        align-items: center;
        .mojeqr_logo {
          width: 25px;
          position: absolute;
          top: 0;
          right: 0;
        }
      }
    }
    /* NAME AND EMAIL SECTION */
    .name_wrap {
      color: $local_black;
      border-bottom: 1px solid $local_border_color;
      padding-bottom: 5px;
      .name {
        font-size: larger;
        font-weight: 600;
      }
      .email {
        font-size: medium;
        display: flex;
        align-items: center;
        gap: 10px;
        .icon {
          cursor: pointer;
          transition: 0.3s;
          &:hover {
            color: $local_purple_dark;
          }
        }
      }
    }
    /* SETTINGS SECTION */
    .settings_wrap {
      border-bottom: 1px solid $local_border_color;
      .title_wrap {
        color: $local_black;
        font-size: larger;
        font-weight: 600;
        border-bottom: 1px solid $local_border_color;
        padding-bottom: 5px;
        margin-top: 5px;
      }
      .settings_elements_wrap {
        position: relative;
        .settings_element_active_indicator {
          width: 5px;
          height: 20px;
          top: 8px;
          transition: 0.3s;
          border-radius: 16px;
          background-color: $local_purple_dark;
          position: absolute;
        }
        /* .settings_element {
          padding: 5px 0px;
          display: flex;
          align-items: center;
          transition: 0.3s;
          gap: 8px;
          cursor: pointer;
          &:hover {
            color: $local_purple_dark;
          }
        } */
        .settings_element_active {
          padding-left: 10px;
          color: $local_purple_dark;
        }
      }
    }
  }
  .how_to_wrap {

    overflow: scroll;
    // calc(100vh - height of header - margin - padding)
    height: calc(100vh - 65px - 30px - 40px);
    .how_to_element_wrap {

      .text {
        font-size: 15px;
        b{
          font-weight: 600;
        }
      }
    }
    .how_to_in_row {
      display: flex;
      flex-direction: row;

    }
  }
  .logout_card {
    @media only screen and (max-width: 1000px) {
      display: flex;
      flex-direction: column;
      max-height: 500px;
      text-align: center;
      .logout_message_for_phones {
        display: flex !important;
      }
    }
    .logout_message_for_phones {
      display: none;
    }
    height: 100%;
    margin-top: 0px !important;
    margin-bottom: 25px !important;
    display: flex;
    align-items: flex-end;

    max-height: 100px;

    .logout_button {
      width: 100%;
      border: 2px solid white;
      background: $local_purple;
      background: linear-gradient(105deg, $local_purple 0%, rgba(188,162,242,1) 100%);
      color: white;
      border-radius: 8px;
      padding: 8px 5px;
      transition: 0.3s;
      cursor: pointer;
      font-weight: bold;
      font-size: 22px;
      &:hover {
        transition: 0.3s;
        transform: scale(1.02);
      }
      &:active {
        font-size: 20px;
      }
    }
  }

  .content_card {
    background-color: transparent !important;
    box-shadow: none;
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    height: 100%;

    transition: 0.5s;
    animation: opening_middle_card_animation 0.5s ease-in-out;

    .header_row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 10px;
      padding-right: 10px;

      .input_wrap {
        display: flex;
        gap: 15px;
        /* width: 60%; */
        .search_bar {
          padding: 10px 15px;
          border-radius: 12px;
          min-width: 180px;
          width: 60%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          input {
            width: 95%;
            min-width: 150px;
            border: none;
            &:focus {
              border: none;
              outline: none;
            }
          }
          .clear_icon {
            cursor: pointer;
            transition: 0.3s;
            &:hover {
              transform: scale(1.1);
            }
          }
          .icon {
            animation: search_icon_animation 0.3s ease-in-out;
            @keyframes search_icon_animation {
              from {
                opacity: 0;
              }
              to {
                opacity: 1;
              }
            }
          }
        }
        .section_selector {

          display: flex;
          align-items: center;
          justify-content: flex-start;
          border-radius: 12px;
          min-width: 250px;
          width: 30%;
          max-width: 30%;
          position: relative;
          .icon {
            display: flex;

          }
          .selector {
            width: calc(100% - 40px);

            height: 100%;
            cursor: pointer;
            display: flex;
            align-items: center;
            padding-left: 10px;
            span {
              overflow: hidden;
              white-space: nowrap;
              animation: selectorAnimation 0.7s ease-in-out;
              @keyframes selectorAnimation {
                0% {
                  width: 0px;
                }
                100% {
                  width: 100%;
                }
              }
            }
          }
          .selector_list_open {
            max-height: 200px !important;
            min-height: 50px !important;
            padding-bottom: 10px !important;
            padding-top: 5px !important;

          }
          .selector_list {
            border-radius: 12px;
            /* padding: 10px 0px 10px 10px !important; */
            background-color: rgba(255, 255, 255, 0.886);
            box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.455);
            transition: 0.3s;
            overflow-y: scroll;
            max-height: 0px;
            width: 100%;
            top: 50px;
            left: 0px;
            position: absolute;
            z-index: 999;
            .selector_element {
              padding: 5px 0px 0px 10px !important;
              cursor: pointer;
              padding-top: 5px;
              &:hover {
                color: $local_purple_dark;
                font-weight: 500;
              }
            }
            .selector_element_active {
              color: $local_purple_dark;
            }
          }
        }
      }
    }
    .table_class_id {
      width: 5%;
      .debug_order {
        font-size: 9px;
      }
    }
    .table_class_name {
      width: calc(18% + 10%);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .table_class_description_text {
      font-size: 14px;
      height: 0px;
      transition: 0.3s;
    }
    .table_class_description {
      width: 10%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      /* cursor: pointer; */
      /* overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis; */
      div {
        display: flex;
        align-items: center;
        transition: 0.3s;
        &:hover {
          .content {
            /* visibility: visible; */
            max-width: 300px;
            padding: 0px 15px 0px 0px;
            color: #6e6e6e;
            cursor: default;
          }
        }
        .content {
          /* visibility: hidden; */
          /* position: absolute; */
          overflow: hidden;
          max-width: 0px;
          white-space: nowrap;
          height: 18px;
          padding: 0px 0px;
          /* margin-left: 20px; */
          background-color: white;
          /* background-color: orange; */
        }
      }
    }
    .table_class_price {
      width: 10%;
      text-align: center;
    }
    .table_class_volume {
      width: 10%;
      text-align: center;
    }
    .table_class_flag {
      width: 8%;
      display: flex;
      align-items: center;
      gap: 5px;
      .flag_icon {
        transition: 0.3s;
        cursor: pointer !important;
        &:hover {
          transform: scale(1.1);
        }
      }
      .not_clickable_icon {
        cursor: default !important;
        &:hover {
          transform: scale(1);
        }
      }
    }
    .table_class_type {
      width: 10%;
      display: flex;
      align-items: center;
      .image_status_type {
        background-color: #239509 !important;
      }
      .icon_status_type {
        background-color: #b10678 !important;
      }
      .status_type {
        background-color: #4975E9;
        color: #ffffff;
        font-size: 14px;
        @media only screen and (max-width: 1600px) {
          font-size: 11px;
          padding-top: 1px;
          padding-bottom: 1px;
        }
        width: 100%;
        font-weight: 400;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        text-align: center;
        cursor: pointer !important;

      }
    }
    .table_class_status {
      width: 10%;
      display: flex;
      align-items: center;
      justify-content: center;
      .icon {
        cursor: pointer !important;
        transition: 0.3s;
        &:hover {
          transform: scale(1.1);
        }
      }
    }
    .table_class_remove {
      width: 5%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .icon {
        cursor: pointer;
        transition: 0.3s;
        &:hover {
          transform: scale(1.1);
        }
      }
    }
    .table_class_move {
      width: 4%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .icon_wrap {
        display: flex;
        align-items: center;
        touch-action: none !important;
      }
    }

    .item_element_header {
      margin-top: 10px;
      margin-bottom: 20px;
      border-radius: 12px;
      padding: 14px 10px 14px 10px;
      margin-left: 10px;
      margin-right: 10px;

      display: flex;
      /* justify-content: space-between; */
      .id {

      }
    }


    .list_row {
      /* position: relative; */
      overflow-y: scroll;
      overflow-x: visible;
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 10px;
      .no_elements_info {
        margin-top: 25px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        color: $local_black;
        align-items: center;
        font-size: 22px;
        font-weight: 500;
        padding-left: 20px;
        padding-right: 20px;
        animation: localAnimation 0.3s ease-in-out;
        .loading_icon_local {
          animation: loading_animation 0.7s infinite;
          position: relative;
          margin-bottom: 15px;
        }
        @keyframes localAnimation {
          0% {
            scale: 0;
          }
          100% {
            scale: 1;
          }
        }
      }
      /* .item_element_row {
        position: relative;
        transition: 0.3s;
        margin-bottom: 8px;
        border-radius: 12px;
        padding: 14px 10px 14px 10px;
        transition: 0.3s;
        display: flex;
        &:hover {
          background-color: rgba(255, 255, 255, 1);
          box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.755);
          transform: translateX(2px);
        }
        animation: itemeElementAnimation 0.3s ease-in-out;
        @keyframes itemeElementAnimation {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }
      } */

      .item_element_row {
        position: relative;
        margin-bottom: 8px;
        border-radius: 12px;
        padding: 14px 10px 14px 10px;
        display: flex;
        touch-action: none;
        &:hover {
          background-color: rgba(255, 255, 255, 1);
          box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.755);
          transform: translateX(2px);
        }
        animation: itemeElementAnimation 0.3s ease-in-out;
        @keyframes itemeElementAnimation {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }
      }
    }
  }

  .mobile_card {
    display: flex;
    justify-content: center;
    margin-bottom: 20px !important;
  }
  .stats_card {
    height: 100%;
    margin-top: 0px !important;
    margin-bottom: 25px !important;
  }


  .col {
    position: relative;
  }
  .left_col {
    animation: leftColAnimation 0.8s ease-in-out;
    width: 380px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .mid_col {
    @media only screen and (max-width: 1000px) {
      display: none;
      animation: none;
    }
    animation: midColAnimation 0.8s ease-in-out;
    /* width: 150vh; */
    /* width: calc(100vw - 380px - 420px); */
    width: 100%;

    /* overflow-y: scroll; */
  }
  .right_col {
    animation: rightColAnimation 0.8s ease-in-out;
    width: 420px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }



  .mobile_frame {
    /* transform: scale(1.2) translateX(-20px) translateY(40px); */

    margin: 0px;
    border: 6px solid #000000;
    border-radius: 32px;
    position: relative;

    $viewport_width: 240px;
    $viewport_height: 465px;

    $mobile_button_width: 4px;
    $mobile_upper_button_top_position: 70px;
    $mobile_lower_button_top_position: 130px;
    width: 240px;
    .sound_button {
      background-color: #000000;
      width: $mobile_button_width;
      height: 50px;
      position: absolute;
      left: -9px;
      top: $mobile_upper_button_top_position;
      border-radius: 15px 0px 0px 15px;
      &:nth-child(2) {
        top: $mobile_lower_button_top_position;
      }
    }
    .lock_button {
      background-color: #000000;
      width: $mobile_button_width;
      height: 50px;
      position: absolute;
      right: -9px;
      top: $mobile_lower_button_top_position;
      border-radius: 0px 15px 15px 0px;

    }

    .camera_cut_wrap {
      z-index: 995;
      width: 100%;
      position: absolute;
      display: flex;
      justify-content: center;
      .camera_cut {
        background-color: #000000;
        width: 90px;
        height: 20px;
        border-radius: 0px 0px 10px 10px;
        display: flex;
        flex-direction: row;
        gap: 5px;
        align-items: center;
        justify-content: center;
        .speaker {
          width: 45px;
          background-color: gray;
          height: 3px;
          border-radius: 20px;
        }
        .camera {
          width: 7px;
          height: 7px;
          background-color: gray;
          border-radius: 100px;
        }
      }
    }
    .mobile_viewport {
      border-radius: 25px;
      width: $viewport_width;
      height: $viewport_height;
      background-image: url("../../images/background_admin.png");
      background-size: cover;
      background-repeat: no-repeat;

      display: flex;
      justify-content: flex-start;
      align-items: flex-start;

      overflow: scroll;


      .mobile_layout_row {
        margin-top: 5px;
        width: calc($viewport_width - 10px);
        padding-left: 5px;
        padding-right: 5px;
        display: flex;
        gap: 5px;
        &:first-child {
          padding-top: 20px;
        }
        &:last-child {
          padding-bottom: 5px;
          .mobile_layout_col {
            border-radius: 16px 16px 20px 20px;

          }
        }

      }
      .mobile_layout_col {
        position: relative;
        border-radius: 16px;
        padding: 0px 5px;
        width: calc(100% - 10px);
        height: calc($viewport_width / 2 - 20px);
        background-color: rgba(255, 255, 255, 0.886);
        box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.455);
        display: flex;
        gap: 2px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        overflow: hidden;
        transition: 0.3s;
        cursor: pointer;
        width: 100%;

        .hidden_section {
          color: #ec3c3c;
          position: absolute;
          top: 4px;
          left: 6px;

        }
        /* &:hover:active {
          transform: scale(1.02);
        } */
        @keyframes mobile_layout_col_animation {
          from {
            height: 0px;
          }
          to {
            height: calc($viewport_width / 2 - 20px)
          }
        }

        &:hover {
          .change_type, .refresh_col, .remove_col {
            transform: scale(1);
          }
        }
        /* animation: 0.5s mobile_layout_col_animation ease-in-out; */
        .change_type {
          transform: scale(0);
          position: absolute;
          top: 5px;
          right: 5px;
          transition: 0.3s;
          cursor: pointer;
          &:hover {
            color: #6134c2;
            transform: scale(1.05);
          }
        }
        .refresh_col {
          transform: scale(0);
          position: absolute;
          bottom: 5px;
          left: 5px;
          transition: 0.3s;
          cursor: pointer;
          &:hover {
            color: #6134c2;
            transform: scale(1.05);
          }
        }
        .remove_col {
          transform: scale(0);
          position: absolute;
          bottom: 5px;
          right: 5px;
          transition: 0.3s;
          cursor: pointer;
          &:hover {
            color: #6134c2;
            transform: scale(1.05);
          }
        }
        .debug {
          text-align: left;
          font-size: 10px;
          line-height: 10px;
          opacity: 0.8;
          position: absolute;
          top: 5px;
          left: 5px;
          white-space: nowrap;
        }
        span {
          line-height: 16px;
          pointer-events: none !important;
          user-select: none !important;
        }
        .icon {
          color: #2c2c2c9b;
        }
        img {
          pointer-events: none !important;
          user-select: none !important;
          filter: invert(1);
          width: 35px;
        }
      }
    }
  }

  .hide_card_animation {
    animation: closing_middle_card_animation 0.2s ease-in-out
  }
}



@keyframes leftColAnimation {
  from {
    transform: translateX(-120vw);
  }
  to {
    transform: translateX(0vw);
  }
}
@keyframes midColAnimation {
  from {
    transform: translateY(-200vh);
  }
  to {
    transform: translateY(0vw);
  }
}
@keyframes rightColAnimation {
  from {
    transform: translateX(120vw);
  }
  to {
    transform: translateX(0vw);
  }
}

@keyframes hideLeftColAnimation {
  from {
    transform: translateX(0vw);
  }
  to {
    transform: translateX(-120vw);
  }
}
@keyframes hideMidColAnimation {
  from {
    transform: translateY(0vh);
  }
  to {
    transform: translateY(-200vw);
  }
}
@keyframes hideRightColAnimation {
  from {
    transform: translateX(0vw);
  }
  to {
    transform: translateX(120vw);
  }
}

@keyframes opening_middle_card_animation {
  from {
    transform: translateY(-150vh);
  }
  to {
    transform: translateY(0vh);
  }
}

@keyframes closing_middle_card_animation {
  from {
    transform: translateY(0vh) !important;
  }
  to {
    transform: translateY(-150vh) !important;
  }
}