.header {
  width: 100%;
  position: relative;
  position: fixed;
  z-index: 999;
  top: 0px;
  left: 0;
  display: flex;
  justify-content: center;
  transition: 0.3s;
  overflow: hidden;

}
.header_wrap {
  width: 100%;
  max-width: 1450px;
  height: $header_height;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
  padding-right: 25px;
  padding-left: 25px;
  /*
  left: 50%;
  transform: translateX(-50%); */


  .logo {
    animation: logoAnimation 0.5s;
    font-weight: bold;
    z-index: 999;
    display: flex;
    align-items: center;
    img {
      cursor: pointer;
      margin-top: 25px;
      margin-left: 10px;
      height: 90px;
    }
  }
  .header_hamburger {
    @media screen and (min-width: 1045px) {
      display: none;
    }
    animation: listAnimation 0.5s;
    display: flex;
    flex-direction: column;
    /* transform: rotate(90deg) translateX(-50px) translateY(-50px); */
    gap: 50px;
    transition: 0.5s;
    position: absolute;
    top: -95px;
    right: 0;
    margin-top: 10px;
    margin-right: 10px;
    color: #555555;
  }
  ul {
    background-color: rgba($color: #ffffff, $alpha: 0.6);
    border-radius: 12px;
    padding: 10px 30px;
    display: flex;
    gap: 15px;
    list-style-type: none;
    animation: listAnimation 0.5s;

    -moz-box-shadow: 0 0 18px #555555;
    -webkit-box-shadow: 0 0 18px #555555;
    box-shadow: 0 0 18px #555555;
    li {
      color: #555555;
      display: flex;
      align-items: center;
      cursor: pointer;
      font-weight: bold;

      font-size: 30px;

      -webkit-user-select: none; /* Safari */
      -ms-user-select: none; /* IE 10 and IE 11 */
      user-select: none; /* Standard syntax */
      border-radius: 8px;
      transition: 0.3s;


      &:hover {
        color: #7944E6;
      }
      &:active {

      }
      img {
        height: 15px;
        margin-top: 2px;
      }
    }

    @media screen and (max-width: 1045px) {
      position: absolute;
      top: 0;
      display: flex;
      flex-direction: column;
      margin-top: 100px;
      padding: 10px 12px;

      background-color: transparent;

      -moz-box-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      li {
        color: white;
        justify-content: flex-start;
      }
    }
  }
  @media screen and (max-width: 1045px) {
    .logo {
      position: absolute;
      top: 0px;
    }
  }
  @keyframes elementClickAnimation {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(0.8);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes listAnimation {
    from {
      transform: translateX(100vh);
    }
    to {
      transform: translateX(0vh);
    }
  }

  @keyframes logoAnimation {
    from {
      transform: translateX(-100vh);
    }
    to {
      transform: translateX(0vh);
    }
  }

  @media (max-width: 576px) {
    /* Styles for medium devices */
    .logo {
      img {
        margin-top: 25px;
        margin-left: 10px;
        height: 70px;
      }
    }
    ul {
      li {
      }
    }

  }
}