.clientsCard {
  padding: 0px 15px;
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #555555 !important;


  width: 100vw;
  max-width: 800px;

  margin-left: auto;
  margin-right: auto;
  align-items: center;
  @media only screen and (max-width: 768px) {
   padding: 0px 0px;
   width: 95vw;
  }
  h1 {
    margin-top: 50px;
    // font-size: xxx-large;
    font-size: 80px;
    line-height: 60px;
  }
  h3 {
    font-weight: 400;
    margin-top: -40px;
    text-align: center;
  }
  @media only screen and (max-width: 768px) {
    /* Styles for medium devices */

    h1 {
      margin-top: 50px;
    }
  }
  .clientsCard__wrap{
    display: flex;
    justify-content: center;
    position: relative;
    gap: 30px;
    margin-bottom: 30px;
   /*  width: 550px; */
    img {
      max-width: 250px;
      border-radius: 8px;
      cursor: pointer;
      transition: 0.3s;
      &:hover {
        transform: scale(1.05);
      }
    }
    .clientCard_jazzCafe {
      max-width: 530px;
    }
    .clientCard_obyvackaBar {
      max-width: 530px;
      position: relative;
      margin-top: -30px;

    }
    .clientCard_mercyCoffe {
      max-width: 310px;
      position: relative;
      margin-top: -30px;
      filter: drop-shadow(5px 5px 5px rgba(0,0,0,0.8));

    }


    @media only screen and (max-width: 768px) {
      /*  flex-direction: column; */
      justify-content: center;
      margin-left: 30px;
      margin-right: 30px;
      img {
        width: 45%;
        max-width: 200px;
      }
      .clientCard_jazzCafe {
        max-width: 430px;
        width: 97%;
      }
      .clientCard_obyvackaBar {
        max-width: 430px;
        width: 97%;
      }
      .clientCard_mercyCoffe {
        max-width: 430px;
        width: 55%;
      }
    }
  }
}