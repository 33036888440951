.introCard {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  color: #555555 !important;
  @media screen and (max-width: 768px) {
    padding-top: 40px;
  }

  cursor: default;

  .trial_wrap {
    animation: riseFromBottom 0.5s cubic-bezier(.51,.62,.24,1.08);
    margin: 50px 0px;
    min-width: 800px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../../../images/trial_background.svg");
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 80px;
    position: relative;
    h4 {
      position: absolute;
      top: 72px;
    }

  }

  h1 {
    // before animation
    // transform: translateY(-100vh);

    z-index: 2;
    text-align: center;
    font-size: 160px;
    line-height: 140px;
    // no selection
    -webkit-user-select: none;
     -moz-user-select: none;
     -ms-user-select: none;
     user-select: none;
     margin-bottom: 40px;

     animation: dropFromTop 0.5s cubic-bezier(.51,.62,.24,1.08);

  }
  @keyframes dropFromTop {
    from {
      transform: translateY(-100vh);
    }
    to {
      transform: translateY(0vh);
    }
  }
  @keyframes riseFromBottom {
    from {
      transform: translateY(100vh);
    }
    to {
      transform: translateY(0vh);
    }
  }
  h2{

    z-index: 2;
    text-align: center;
    font-weight: normal;
    font-size: 32px;
    line-height: 50px;
    margin-top: 0;
    // no selection
    -webkit-user-select: none;
     -moz-user-select: none;
     -ms-user-select: none;
     user-select: none;
     animation: dropFromTop 0.5s cubic-bezier(.51,.62,.24,1.08); // also set delay in IntroCard.tsx useEffect
  }
  button {

    animation: riseFromBottom 0.5s cubic-bezier(.51,.62,.24,1.08); // also set delay in IntroCard.tsx useEffect

    z-index: 2;
    //background-color: rgba($green_color, .8);
    // color: $purple_color;
    border: none;
    font-family: 'Kanit', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    padding: 20px 100px;
    border-radius: 8px;
    cursor: pointer;
    transition: 0.2s;

    -moz-box-shadow: 0 0 18px #555555;
    -webkit-box-shadow: 0 0 18px #555555;
    box-shadow: 0 0 18px #555555;
    background-color: rgba($color: #ffffff, $alpha: 0.6);

    h3 {
      z-index: 2;
      margin: 0;
      font-size: 40px;
      font-weight: bolder;

      color: #7944E6;

      /* background: -webkit-linear-gradient(left, $green_color, $purple_color);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent; */
    }
    &:hover {
      box-shadow: 12px 12px 2px 1px rgba(#555555, 0.4);
    }
    &:active {
      box-shadow: 0px 0px 0px 0px rgba(#555555, 0.4);
    }
    &:focus {

    }
  }

  .introCard_divider {

    animation: riseFromBottom 0.5s cubic-bezier(.51,.62,.24,1.08); // also set delay in IntroCard.tsx useEffect

    z-index: 2;
    width: 80%;
    height: 5px;
    margin: 50px 0px;
    background: linear-gradient(90deg,  $purple_color 0%, $green_color 35%, $purple_color 100%);
  }

  h4 {
    color: #7944E6;
    // animation: riseFromBottom 0.5s cubic-bezier(.51,.62,.24,1.08); // also set delay in IntroCard.tsx useEffect


    z-index: 2;
    margin: 0;
    text-align: center;
    font-size: 25px;
    font-weight: 700;
  }

  /* Small devices (up to 576px) */
  @media only screen and (max-width: 576px) {
    /* Styles for small devices */
    justify-content: flex-center;
    margin-top: 0px;
    // padding-top: 30px;
    .trial_wrap {
      transform: translateY(0px) !important;
      margin: 30px 0px;
      /* min-width: 800px; */
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-image: none;
      background-color: white;
      width: 70%;

      h4 {
        color: #5E45D6;
      }
    }
    h1 {
      font-size: 80px;
      line-height: 65px;
      margin-bottom: 30px;
    }
    h2{
      margin-top: 20px;
      font-size: 25px;
      line-height: 25px;
    }
    button {
      margin-top: 20px;
      padding: 20px 60px;
      h3 {
        font-size: 20px;
      }
    }
    .introCard_divider {
      width: 80%;
      margin-top: 30px;
      margin-bottom: 10px;
    }
    h4 {
      font-size: 20px;
      font-weight: 700;
    }
  }

  /* Medium devices (576px to 768px) */
  @media (min-width: 576px) and (max-width: 768px) {
    /* Styles for medium devices */
  }

  /* Large devices (768px to 992px) */
  @media (min-width: 768px) and (max-width: 992px) {
    /* Styles for large devices */
  }

  /* Extra-large devices (992px to 1200px) */
  @media (min-width: 992px) and (max-width: 1200px) {
    /* Styles for extra-large devices */
  }

  /* Extra-extra-large devices (1200px and above) */
  @media (min-width: 1200px) {
    /* Styles for extra-extra-large devices */
    @media (max-height: 756px) {

      justify-content: flex-center;
      padding-top: 40px;
      h1 {
        font-size: 140px;
        line-height: 105px;
        margin-bottom: 30px;
      }
      h2{
        margin-top: 0px;
        font-size: 50px;
        line-height: 45px;
      }
      button {
        margin-top: 0px;
        padding: 20px 80px;
        h3 {
          font-size: 25px;
        }
      }
      .introCard_divider {
        width: 80%;
        margin: 30px 0px;
      }

    }
  }
  @media only screen and (min-width: 578px) and (max-height: 736px) {
    // ntb size
    margin-top: 0px;
    h1 {
      font-size: 100px;
      line-height: 80px;
      margin-bottom: 10px;
    }
    h2{
      margin-top: 0px;
      font-size: 40px;
      line-height: 35px;
    }
    button {
      margin-top: 0px;
      padding: 20px 80px;
      h3 {
        font-size: 20px;
      }
    }
    .introCard_divider {
      width: 80%;
      margin: 20px 0px;
    }

  }

}