.default_layout {
  display: flex;
  flex-direction: column;
  overflow: hidden !important;
}
.admin_panel_layout {
  display: flex;
  flex-direction: column;
  overflow: hidden !important;
  justify-content: space-between;
  min-height: 100vh;
}