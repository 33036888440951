.clientContact {
  display: flex;
  z-index: 2;
  padding: 5px 30px 10px 30px;
  gap: 30px;
  height: 30px;
  .icon {
    transition: 0.3s;
    cursor: pointer;
    &:hover {
      transform: scale(1.1);
    }
  }
}
.rotate {
  animation: 1s rotate infinite linear;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.flag_element {
  height: 20px;
  /* padding-right: 4px; */
  padding-left: 4px;
  padding-top: 2px;
}
.global_client_class {
  min-height: 100vh;
  overflow-x: hidden !important;
  .panorama_open_close_all_button {
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    padding: 5px;
    position: fixed;
    top: 60px;
    right: 25px;
    z-index: 99;
    transition: 0.3s;
  }
  /* Code for Firefox */
  ::-moz-selection {
    color: black;
    background: $white_color;
  }
  ::selection {
    color: black;
    background: $white_color;
  }

  width: 100vw;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  .logo_wrap {
    z-index: 50 !important;
    margin: 10px 0px 5px 0px;
    margin-top: -40px;
    padding: 0px;
    .logo {
      z-index: 50 !important;
      @media only screen and (max-width: 576px) {
      }
    }
  }

  .marginTopLocalAdjustment {
    @media only screen and (max-width: 576px) {
      margin-top: -80px;
    }
  }
  .main_content_wrap {
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    .row {
      display: flex;
      gap: 0px;
      width: 100%;

      overflow: hidden !important;

    }
    @media only screen and (max-width: 576px) {
      // margin-top: -80px;

      /* flex-direction: row;
      align-items: flex-start;
      gap: 10px; */
    }
    overflow-x: scroll;
    width: 95vw;
    max-width: 700px;
    position: relative;
  }
  .panorama_page_element_open {
    justify-content: flex-start !important;
    align-items: center !important;
    flex-direction: column;
    .title_wrap {
      gap: 5px !important;
      overflow: hidden !important;
      flex-direction: row !important;
      h1 {
        transform: translateY(10px) translateX(10px);
      }
    }
  }
  .panorama_page_element_close {

  }
  .panorama_page_element_close_title_in_row {
    .title_wrap {
      gap: 5px !important;
      /* overflow: hidden !important; */
      flex-direction: row !important;
      h1 {
        transform: translateY(10px) translateX(10px);
      }

      padding-bottom: 25px;
      @media only screen and (max-width: 576px) {
        padding-bottom: 20px;
        // flex-direction: column;
      }
    }
    /* padding-bottom: 30px !important; */
  }

  .loaded_animation {
    opacity: 1 !important;
    transform: translateY(0px) !important;

    animation: blockAnimation 0.4s ease-out;
    @keyframes blockAnimation {
      from {
        opacity: 0;
        transform: translateY(10px);
      }
      to {
        opacity: 1 !important;
        transform: translateY(0px) !important;
      }
    }

  }

  .panorama_page_element {
    /* opacity: 0;
    transform: translateX(-400px);
    &:nth-child(even) {
      transform: translateX(400px);
    } */
    opacity: 0;
    transform: translateY(10px);


    cursor: pointer;
    @media only screen and (max-width: 576px) {
      cursor: default;
      min-width: 22vw;
      padding: 10px 10px 0px 10px;
      // flex-direction: column;
    }
    padding: 20px 20px 0px 20px;
    flex: 2;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    margin: 5px 5px;

    max-width: 100vw;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    transition: 0.1s;
    .divider {
      margin-top: 15px;
      position: relative;
      height: 5px;
      border-radius: 8px;
    }
    .items_wrap {
      overflow: hidden;
      width: 100%;
      .imgOffer {
        width: 100%;
        border-radius: 8px;
      }
      .blocks_wrap {
        display: flex;
        gap: 15px;
        flex-wrap: wrap;
        margin-top: 15px;
        padding-bottom: 15px;
        .block_item {
          flex: 1 0 calc(20%); /* Adjust the width and gap as needed */
          box-sizing: border-box;
          border-radius: 8px;
          // width: 25%;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 10px;
          line-height: 16px;
          justify-content: flex-end;
          background-color: #707070;
          text-align: center;
          padding: 20px 25px;
          transition: 0.3s;
          @media only screen and (max-width: 576px) {
            padding: 15px 15px;
          }
        }
      }

      .menu_item_element {
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: space-between;
        padding-bottom: 15px;
        .title_row {
          display: flex;
          justify-content: space-between;
          .title {
            transition: 0.3s;
            font-weight: 500;
            line-height: 20px;
            font-size: 20px;
            max-width: 65vw !important;
            font-size: 20px !important;
            display: flex;
            justify-content: center;
            .spicyLevel {
              font-size: 12px;
            }
            .title_descr_icon_wrap {
              margin-right: 15px;
              margin-top: 0px;
              .icon {
                color: #F7C808;
              }
            }
          }
          .price {
            transition: 0.3s;
            font-weight: 500;
            line-height: 18px;
            font-size: 18px;
            white-space: nowrap;
          }
        }
        .description_row {
          display: flex;
          justify-content: space-between;
          .descr {
            transition: 0.3s;
            font-weight: 300;
            min-height: 12px;
            overflow-y: hidden;
            overflow-x: visible;
            overflow-y: hidden;
            margin-right: 10px;
            padding-bottom: 5px;

            line-height: 18px;
            font-size: 16px;
          }
          .volume {
            transition: 0.3s;
            white-space: nowrap;
            line-height: 18px;
            font-size: 16px;
          }
        }
      }
    }
    .title_wrap {
      width: 100%;
      .img_wrap {
        padding: 5px 0px !important;
        text-align: center;
      }
      img {
        transition: 0s;
        /* transition 0.3s */
        transform: translateY(10px);
      }
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      gap: 0px;
      margin-bottom: 5px;

      h1 {
        line-height: 25px;
        font-size: 25px;
        font-weight: 500;
        text-align: center;
      }
      @media only screen and (max-width: 576px) {
        h1 {
          line-height: 20px;
          font-size: 20px;
        }
      }
    }
  }
}