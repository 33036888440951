@media only screen and (max-width: 425px) {

  input, textarea, select {
    font-size: 16px; /* Nastavte font size na minimálne 16px */
  }
}
.login_page {
  display: flex;
  justify-content: center;
  height: 100vh;

 /*  */

  .login_card_hide {
    animation: 0.5s hideLogin ease-in-out;
    @keyframes hideLogin {
      from {
        transform: translateY(0vh);
      }
      to {
        transform: translateY(120vh);
      }
    }
  }

  .login_card_wrap {
    animation: 0.8s loginCardAppear ease;
    overflow: hidden;
    color: #2C2C2C;
    display: flex;
    /* width: 70%;
    height: 100%; */
    /* width: 90vw;
    height: 90vh; */
    max-height: 700px;
    box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.455);
    border-radius: 16px;
    margin: 80px;

    @media only screen and (max-width: 970px) {
      max-height: 550px;
      width: 100%;
      margin: 20px;
      .login_card_left_side {
        padding-right: 15px !important;
        padding-left: 15px !important;

        .mobile_img_wrap {
          display: block !important;
          margin-top: 50px;
          width: 100%;

          .img_bg {
            margin-left: auto;
            margin-right: auto;
            background-color: #6A39E2;
            border-radius: 100%;
            height: 120px;
            width: 120px;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              width: 80px;
              height: 80px;
            }
          }
        }
      }
    }


    .login_card_left_side {
      width: 100%;
      border-radius: 16px 0px 0px 16px;
      background-color: rgba(255, 255, 255, 0.80);
      /* padding-bottom: 200px; */
      padding-right: 105px;
      padding-left: 105px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: -50px;
      .mobile_img_wrap {
        display: none;
      }
      .title_wrap {
        /* margin-top: 100px;
        margin-bottom: 50px; */
        text-align: center;
        .title{
          font-size: 32px;
          font-weight: bold;
        }
      }
      .global_input_wrap {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        input {
          touch-action: manipulation !important; /* Optimalizácia pre dotykové zariadenia */
          -webkit-text-size-adjust: 100% !important; /* Zakáže prispôsobenie veľkosti textu v Safari */
        }
      }
      .input_wrap {
        position: relative;
        display: flex;
        flex-direction: column;
        text-align: left;
        width: 100%;
        max-width: 280px;
        /* width: 70%;
        min-width: 240px; */
        .eye_icon {
          position: absolute;
          right: 15px;
          bottom: 8px;
          cursor: pointer;
          transition: 0.3s;
          &:hover {
            transform: scale(1.1);
          }
        }
        label {
          margin-bottom: 5px;
          font-weight:bold;
        }
        input {
          border: 2px solid #8E62EA;
          border-radius: 12px;
          padding: 15px 10px;
          background-color: #EAEAEA;
          &::placeholder {
            color: #B1B1B1;
          }
          &:active {
            outline: none;
          }
          &:focus {
            outline: 1px solid #8E62EA;;
          }
        }
      }
      .login_button_wrap {

        transition: 0.3s;
        button {
          margin-top: 25px;
          border: 2px solid white;
          background: rgb(114,59,229);
background: linear-gradient(105deg, rgba(114,59,229,1) 0%, rgba(188,162,242,1) 100%);
          color: white;
          border-radius: 8px;
          padding: 10px 5px;
          transition: 0.3s;
          cursor: pointer;
          font-weight: bold;
          font-size: 22px;
          &:hover {
            transition: 0.3s;
            transform: scale(1.02);
          }
          &:active {
            font-size: 20px;
          }
        }
        button:nth-child(2) {
          margin-top: 25px;
          border: 2px solid rgb(114,59,229);
          background: white;
          color: rgb(114,59,229);
          border-radius: 8px;
          padding: 8px 5px;
          transition: 0.3s;
          cursor: pointer;
          font-weight: bold;
          font-size: 22px;
          &:hover {
            transition: 0.3s;
            transform: scale(1.02);
          }
          &:active {
            font-size: 20px;
          }
        }

      }
    }
    .login_card_right_side {

      @media only screen and (max-width: 970px) {
        display: none;
      }
      width: 100%;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-right: 105px;
      padding-left: 105px;
      align-items: center;
      margin-top: -50px;
      background: rgb(113,58,229);
      background: linear-gradient(135deg, rgba(113,58,229,1) 0%, rgba(147,126,231,1) 100%);
      border-radius: 0px 16px 16px 0px;
      .image_wrap {
        margin-top: 80px;
        background-color: white;
        border-radius: 100%;
        padding: 20px;
        padding-bottom: 10px;
        img {
          max-width: 140px;
        }
      }

      .tips_wrap {
        color: white;
        .tips {
          margin-top: 50px;
          font-weight: 500;
          font-size: 24px;
        }
        .dots {
          margin-top: 10px;
          font-size: 64px;
        }
      }

    }
  }

}

@keyframes loginCardAppear {
  from {
    transform: translateY(120vh);
  }
  to {
    transform: translateY(0vh);
  }
}