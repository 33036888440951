@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&family=Exo+2:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Open+Sans:wght@600;700&family=Righteous&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&family=Exo+2:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Montserrat:wght@300;400;500;600;700;800;900&family=Open+Sans:wght@600;700&family=Righteous&display=swap');
$green_color: #00CC99;
$purple_color: #9300ff;

$main_color: #070507;
$black_color: #46464e;
$white_color: #ffffff;
$grey_color: #afabab;
$grey_color_darker: #464646;
$splash_background: #F6EDDE;

$header_height: 100px;




