.pricingCard {
  padding: 0px 15px;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  color: #555555 !important;


  width: 100vw;
  max-width: 800px;

  margin-left: auto;
  margin-right: auto;

  align-items: center;
  @media only screen and (max-width: 768px) {
   padding: 0px 0px;
   width: 95vw;
  }
  h1 {
    margin-top: 50px;
    // font-size: xxx-large;
    font-size: 80px;
    line-height: 60px;
  }
  h3 {
    font-weight: 400;
    margin-top: -40px;
  }
  @media only screen and (max-width: 768px) {
    /* Styles for medium devices */

    h1 {
      margin-top: 50px;
    }
  }
}
.pricingCard__wrap {
  min-width: 100%;
  height: auto;
  padding: 40px 80px;
  gap: 60px;
  display: flex;
  justify-content: center;
  margin-top: 40px;
  @media only screen and (max-width: 768px) {
    margin-top: 10px;
    flex-direction: column;
    align-items: center;
    gap: 120px;
  }
  .element {

    transform: scale(1.05);
    transition: 0.3s;
    cursor: default;
    /* &:hover {
      transform: scale(1);
    } */
    width: 220px;
    background-color: white;
    color: gray;

    border-radius: 16px;
    padding: 15px 50px;
    text-align: center;

    display: flex;
    justify-content: center;

    div {
      ul {
        padding: 0;
        list-style: none;
        /* text-align: start; */
        li {
          line-height: 20px;
          margin-bottom: 10px;
        }
      }
    }
    position: relative;
    box-shadow: 0px 13px 42px 17px rgba(0,0,0,0.3);
    -webkit-box-shadow: 0px 13px 42px 17px rgba(0,0,0,0.3);
    -moz-box-shadow: 0px 13px 42px 17px rgba(0,0,0,0.3);
    .content {
      padding-top: 120px;
    }
    .description {
      // display: flex;
      padding-top: 50px;
      padding-bottom: 15px;
    }
    .price_wrap {
      display: flex;
      position: relative;
    }
    .price {
      background-color: white;
      border-radius: 100%;
      height: 50px;
      width: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 100;
      position: absolute;
      padding: 30px;
      top: -80px;
      box-shadow: 0px 13px 42px 17px rgba(0,0,0,0.3);
      -webkit-box-shadow: 0px 13px 42px 17px rgba(0,0,0,0.3);
      -moz-box-shadow: 0px 13px 42px 17px rgba(0,0,0,0.3);
      span {
        font-size: 30px;
        font-weight: bold;
        line-height: 20px;
        color: #9300ff;
        span {
          font-weight: normal;
          font-size: 14px;
          color: gray;
        }
      }
    }
    .upper_part{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      img {
        position: absolute;
        width: 110%;
        left: -5%;
        top: -5%;
      }
      span {
        cursor: default;
        position: absolute;
        z-index: 100;
        top: 48px;
        line-height: 34px;
        color: white;
        font-weight: 500;
        font-size: 32px;
      }
    }
    .action {
      background: linear-gradient(148deg, #9300ff 0%, rgba(231,25,125,1) 100%);
      font-weight: 500;
      color: white;
      border-radius: 100px;
      width: 70%;
      cursor: pointer;
      transition: 0.3s;
      &:hover {
        transform: scale(1.05);
      }
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50px;
      bottom: -25px;
      box-shadow: 0px 13px 42px 17px rgba(0,0,0,0.3);
      -webkit-box-shadow: 0px 13px 42px 17px rgba(0,0,0,0.3);
      -moz-box-shadow: 0px 13px 42px 17px rgba(0,0,0,0.3);
    }
  }
}