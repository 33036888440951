.home_page {
  // min-height: calc(100vh - 80px);
  min-height: 100vh;
  overflow: hidden;

  /* padding-top: $header_height;
  padding-left: 30px;
  padding-right: 30px; */
  display: flex;
  flex-direction: column;
  align-items: center;

  // justify-content: center;
  position: relative;
}

